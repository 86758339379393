import React from 'react'
import { CartLineQuantityAdjustButton, CartLineQuantity } from '@shopify/hydrogen-react';

export default function CartLineQuantityAdjust({lineId, quantity}) {
  return (
    <>
      <label htmlFor={`quantity-${lineId}`} className="sr-only">
        Quantity, {quantity}
      </label>
      <div className="flex items-center overflow-auto border rounded">
        <CartLineQuantityAdjustButton
          adjust="decrease"
          aria-label="Decrease quantity"
          className="h-[25px] flex justify-center items-center px-2 py-[0.125rem] transition text-primary/40 hover:text-primary disabled:pointer-events-all disabled:cursor-wait overflow-hidden"
        >
          &#8722;
        </CartLineQuantityAdjustButton>
        <CartLineQuantity
          as="div"
          className="h-[20px] flex justify-center items-center text-center px-2 text-primary/90 overflow-hidden"
        />
        <CartLineQuantityAdjustButton
          adjust="increase"
          aria-label="Increase quantity"
          className="h-[25px] flex justify-center items-center px-2 py-[0.125rem] transition text-primary/40 hover:text-primary disabled:pointer-events-all disabled:cursor-wait overflow-hidden"
        >
          &#43;
        </CartLineQuantityAdjustButton>
      </div>
    </>
  );
}
