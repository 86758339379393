import React, {useState} from 'react'
import { CartLineProvider, useCart} from '@shopify/hydrogen-react'

import Meta from '../../utils/meta'
import CartCheckoutActions from './components/cartCheckoutActions'
import CartEmpty from './components/cartEmpty'
import CartLineItem from './components/cartLineItem'
import CartSummary from './components/cartSummary'
import CartDiscount from './components/cartDiscount'
import Navbar from '../../ui-components/navbar'

export default function Cart() {
  const { lines } = useCart();
  const [discountCode, setDiscountCode] = useState('');

  return (
    <div className='w-full h-[calc(100dvh)]'>
      <Meta 
        title='Cart - Rugtomize'
        description='Manage your cart and checkout!'
      />
      <Navbar />

      {lines.length === 0 && <CartEmpty />}
      {
        lines.length > 0 && 
          <form className="grid grid-cols-1 grid-rows-[1fr_auto] h-[calc(100dvh-4rem)] pt-5">
            <section
              aria-labelledby="cart-contents"
              className="px-4 pb-2 overflow-auto transition md:px-12"
            >
              <ul className="grid gap-5 md:gap-10">
                {lines.map((line) => {
                  return (
                    <CartLineProvider key={line.id} line={line}>
                      <CartLineItem />
                    </CartLineProvider>
                  );
                })}
              </ul>
            </section>
            <section
              aria-labelledby="summary-heading"
              className="p-4 border-t md:px-12"
            >
              <h2 id="summary-heading" className="sr-only">
                Order summary
              </h2>
              <CartDiscount discountCode={discountCode} setDiscountCode={setDiscountCode} />
              <CartSummary />
              <CartCheckoutActions />
            </section>
          </form>
      }
    </div>
  );
}