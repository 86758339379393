import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { AdvancedImage } from '@cloudinary/react'

import Meta from '../../../utils/meta'
import { cld } from '../../../utils/constants'
import Navbar from '../../../ui-components/navbar'
import Footer from '../../../ui-components/footer'
import { Link } from 'react-router-dom'

const faqs = [
  {
    question: "What material should I select?",
    answer:
      "This depends on two factors. Where the rug will be placed and how durable or plush you want your rug to be. Looking to be on the safe side? Check out our pack of swatches, which includes all the materials we currently offer. For more information give us a call and we will be happy to help.",
  },
  {
    question: "What size is right for me?",
    answer:
      "Depends on the function! Most living room area rugs range from 5' x 7' to 8' x 10' however most of our customers buy personal expressive pieces for their rooms or intimate personal spaces, these rugs range in the 3' to 5' range."
  },
  {
    question: "Are there any limitations on colors?",
    answer:
      "NOPE! The best part about your custom rug from Rugtomize is that you have full creative power to put any colors or designs to work on your floor!"
  },
  {
    question: "How soon can I expect my rug to ship after my purchase?",
    answer:
      "We take pride in our quick and speedy delivery of your rug, you should receive it in no longer than 3-4 weeks. We make rugs every week so it's likely your rug will take 1-2 weeks to make depending on where your order comes in our production schedule."
  },
  {
    question: "What is your cancellation, returns and refunds policy?",
    answer:
      "We currently do not offer cancellations or returns on custom orders as your order will be processed and production begun within 24 hours of receiving."
  },
  {
    question: "How do I become an Artist at Rugtomize?",
    answer:
      "Our artist collaboration platform is currently on an invite only acceptance basis. Please contact partner@rugtomize.com for more information."
  },
  {
    question: "How do I clean my custom Rug?",
    answer:
      <>See <Link to='/rug/care' className='text-amber-500 underline'>Care</Link> page for more information.</>
  },
]

export default function FAQ() {
  const faqImage = cld.image('assets/images/FAQ_dsrbfe')

  return (
    <main>

      <Meta
        title='FAQ - Rugtomize'
        description='Frequently asked questions about our company and our rugs'
      />

      <Navbar />

      <div className='w-full'>
        <AdvancedImage
          cldImg={faqImage}
          className="h-56 md:h-96 w-full object-cover"
          alt="Person holding a rug with a question mark"
        />
        <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900 text-center mt-[2vh]">Frequently asked questions</h2>
      </div>

      <div className="bg-white mb-[5vh]">
        <div className="mx-auto max-w-7xl px-6 sm:py-32 lg:px-8 lg:py-10">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
            <dl className="mt-5 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">{faq.question}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <Footer />
    </main>
  )
}
