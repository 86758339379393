import React from 'react'
import { Helmet } from 'react-helmet'

export default function Meta({ title, description}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="UTF-8" />
      <meta name="description" content={description} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}
