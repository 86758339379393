import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { AdvancedImage } from '@cloudinary/react';

import { cld } from '../utils/constants';
import { subscribeToKlaviyo } from '../services/backendService';
import { ArrowDownIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

const navigation = {
  shop: [
    { name: 'Custom Shaped Rugs', href: '/customize/editor' },
  ],
  company: [
    { name: 'About Us', href: '/company/aboutus' },
    { name: 'Reviews', href: '/reviews'},
		{ name: 'Contact', href: '/company/contact' },
		{ name: 'FAQ', href: '/company/faq' },
    { name: 'Rug Care', href: '/rug/care'}
  ],
  legal: [
    { name: 'Refund Policy', href: '/legal/refundpolicy' },
    { name: 'Privacy Policy', href: '/legal/privacypolicy' },
    { name: 'Terms of Service', href: '/legal/termsofservice' },
  ],
  social: [
    {
      name: 'TikTok',
      href: 'https://www.tiktok.com/@rugtomize',
      icon: (props) => (
        <svg fill="#6b7280" width="24px" height="24px" viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg" stroke="#6b7280"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"></path></g></svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/rugtomize/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/customizerug/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/rugtomize',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    }
  ],
}

export default function Footer() {
  const [selectedNavigation, setSelectedNavigation] = useState([]);
  const [email, setEmail] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 1023px)' })

  const whiteLogo = cld.image('assets/logos/rugtomize-white_zdlkxm');

  return (
    <footer className="bg-black" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-8 sm:pt-8 lg:px-8 lg:pt-5">
        <div className='flex flex-col items-center justify-center w-full'>
          <AdvancedImage
            cldImg={whiteLogo}
            alt="Company Logo with the name in white"
            className="h-10"
          />

          <div className='flex flex-col lg:flex-row lg:justify-between w-full mt-10'>
            <div 
              className={
                classNames(
                "mt-10 lg:mt-0 lg:h-full overflow-hidden",
                selectedNavigation.includes('SHOP') ? 'h-full' : 'h-5'
              )}
            >
              <div className='flex flex-row items-center space-x-2'>
                {isMobile && <ChevronDownIcon className='h-5 w-5 text-white' />}
                <button 
                  className="text-lg font-semibold text-white"
                  onClick={() => {
                    if(selectedNavigation.includes('SHOP')) setSelectedNavigation(selectedNavigation.filter(item => item !== 'SHOP'));
                    else setSelectedNavigation([...selectedNavigation, 'SHOP']);
                  }}
                >
                  SHOP
                </button>
              </div>
              <ul className="mt-6 ml-3 lg:ml-0 space-y-4">
                {navigation.shop.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div 
              className={
                classNames(
                "mt-10 lg:mt-0 lg:h-full overflow-hidden",
                selectedNavigation.includes('COMPANY') ? 'h-full' : 'h-5'
              )}
            >
              <div className='flex flex-row items-center space-x-2'>
                {isMobile && <ChevronDownIcon className='h-5 w-5 text-white' />}
                <button 
                  className="text-lg font-semibold text-white"
                  onClick={() => {
                    if(selectedNavigation.includes('COMPANY')) setSelectedNavigation(selectedNavigation.filter(item => item !== 'COMPANY'));
                    else setSelectedNavigation([...selectedNavigation, 'COMPANY']);
                  }}
                >
                  COMPANY
                </button>
              </div>
              <ul className="mt-6 ml-3 lg:ml-0 space-y-4">
                {navigation.company.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div 
              className={
                classNames(
                "mt-10 lg:mt-0 lg:h-full overflow-hidden",
                selectedNavigation.includes('LEGAL') ? 'h-full' : 'h-5'
              )}
            >
              <div className='flex flex-row items-center space-x-2'>
                {isMobile && <ChevronDownIcon className='h-5 w-5 text-white' />}
                <button 
                  className="text-lg font-semibold text-white"
                  onClick={() => {
                    if(selectedNavigation.includes('LEGAL')) setSelectedNavigation(selectedNavigation.filter(item => item !== 'LEGAL'));
                    else setSelectedNavigation([...selectedNavigation, 'LEGAL']);
                  }}
                >
                  LEGAL
                </button>
              </div>
              <ul className="mt-6 ml-3 lg:ml-0 space-y-4">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        
        {/*<div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <AdvancedImage
            cldImg={whiteLogo}
            alt="Company Logo with the name in white"
            className="h-7"
          />

          <div className="mt-10 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Shop</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.shop.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 lg:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Company</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 lg:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>*/}

        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-white">Subscribe to our newsletter</h3>
            <p className="mt-2 text-sm leading-6 text-gray-300">
              Subscribe to our newsletter to get 15% OFF on your first order + Free Shipping!
            </p>
          </div>
          <div className="mt-6 sm:flex sm:max-w-lg lg:mt-0">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autoComplete="email"
              required
              className="w-full min-w-0 appearance-none rounded-lg border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-amber-400 sm:w-56 sm:text-sm sm:leading-6"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
              <button
                onClick={async () => {
                  const result = await subscribeToKlaviyo(email);
                  console.log(result);
                  setEmail('');
                }}
                className="flex w-full items-center justify-center rounded-lg bg-amber-400 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-400"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-white/10 pt-8 lg:flex lg:items-center lg:justify-between">
          <div className="flex space-x-6 lg:order-2">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-xs leading-5 text-gray-400 lg:order-1 lg:mt-0">
            &copy; 2023 Rugtomize, LLC. All rights reserved.
          </p>
        </div>

        <div className='mt-12'>
          <p className="text-sm leading-5 italic text-gray-400 lg:order-1 lg:mt-0">*Shipping Exclusions Apply</p>
          <p className="mt-4 text-xs leading-5 italic text-gray-400 lg:order-1 lg:mt-0">
            Excluding Hawaii, Alaska, U.S. Territories, and Canada. We do not ship to P.O. Boxes (including APO and FPO) or the U.S. Territories.
          </p>
        </div>
      </div>
    </footer>
  )
}