import React, {useEffect, useState} from 'react';
import { usePapaParse } from 'react-papaparse';
import { useMediaQuery } from 'react-responsive'
import { AdvancedImage } from '@cloudinary/react'
import { CheckBadgeIcon, StarIcon } from '@heroicons/react/24/solid';

import customerReviews from './reviews.csv'
import { cld } from '../../../utils/constants'
import Meta from '../../../utils/meta';
import Navbar from '../../../ui-components/navbar';
import Footer from '../../../ui-components/footer';

export default function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [chunks, setChunks] = useState([]);
  const { readString } = usePapaParse();

  const isMobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const colNumber = isMobile ? 1 : 5;

  const handleReadRemoteFile = () => {
    readString(customerReviews, {
      complete: (results) => {
        setReviews(results.data);
      },
      download: true,
      header: true,
      error: (error, file) => {
        console.log('error', error, file);
      }

    });
  }

  useEffect(() => {
    handleReadRemoteFile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const reviewsWithImages = reviews.filter((review) => review.img !== "");
    const reviewsWithoutImages = reviews.filter((review) => review.img === "");

    const chunkSize = reviewsWithoutImages.length / colNumber;
    const chunkWithImagesSize = reviewsWithImages.length / colNumber;

    let chunkIterator = 0;
    for(let i = 0; i < reviewsWithImages.length; i++) {
      if(
        reviewsWithImages[i].nickname === "Eric B." || 
        reviewsWithImages[i].nickname === "Larry A." ||
        reviewsWithImages[i].nickname === "Keysha P." ||
        reviewsWithImages[i].nickname === "Mike R." ||
        reviewsWithImages[i].nickname === "Francisco S." ||
        reviewsWithImages[i].nickname === "Dave H." ||
        reviewsWithImages[i].nickname === "Aldric H." ||
        reviewsWithImages[i].nickname === "Robert S."
      ) {
        const temp = reviewsWithImages[i];
        reviewsWithImages.splice(i, 1);
        if(chunkIterator === 0) reviewsWithImages.unshift(temp);
        else reviewsWithImages.splice(chunkIterator, 0, temp);

        chunkIterator += chunkWithImagesSize;
        if(chunkIterator > 28.5) chunkIterator = 0;
        console.log(chunkIterator)
      }
    }

    const chunks = [];
    const chunksWithImages = [];

    for (let i = 0; i < reviewsWithImages.length; i += chunkWithImagesSize) {
      chunksWithImages.push(reviewsWithImages.slice(i, i + chunkWithImagesSize));
    }

    for (let i = 0; i < reviewsWithoutImages.length; i += chunkSize) {
      chunks.push(reviewsWithoutImages.slice(i, i + chunkSize));
    }

    const arrayOfReviewsWithImages = [];
    for (let i = 0; i < chunks.length; i++) {
      console.log("chunks[i]", chunks[i]);
      console.log("chunksWithImages[i]", chunksWithImages[i]);
      arrayOfReviewsWithImages.push(chunksWithImages[i].concat(chunks[i]));
    }

    console.log("chunks", chunks);
    console.log("chunksWithImages", chunksWithImages);

    setChunks(arrayOfReviewsWithImages);
  }, [reviews, colNumber]);

  const starsImage = cld.image('assets/images/stars_bani0m');

  return (
    <main className="w-full h-full bg-gray-100">
      <Meta
        title='Customer Reviews - Rugtomize'
        description='See what our customers have to say about their experience with Rugtomize!'
      />
      
      <Navbar />

      <div className='mt-10 h-fit'>
        <div className='flex flex-row items-center justify-center'>
          <AdvancedImage
            cldImg={starsImage}
            alt='Stars'
            width={80}
            height={80}
            className='ml-2'
          />
          <p className='md:text-lg ml-2 text-sm font-semibold'>Over <span className='font-extrabold text-violet-500'>Hundreds</span> Happy Customers!</p>
        </div>
        <h1 className='text-3xl md:text-5xl font-bold text-center w-full'>People Love Our Rugs</h1>
      </div>

      {
        chunks && 
          <div className='flex flex-row w-full space-x-4 px-1 mt-5 mb-10'>

            {
              [...Array(chunks.length)].map((e, i) => {
                return (
                  <div key={i} className='flex w-full flex-col space-y-4'>
                    {
                      chunks[i] && chunks[i]
                        .filter((review) => review.id !== "")
                        .filter((review) => parseInt(review.rating) >= 3)
                        .sort((review) => review.img === "")
                        .map((review) => (
                          <div key={review.id} className='flex flex-col items-center bg-white rounded-lg shadow h-fit'>
                            {
                              review.img !== "" && 
                                <img
                                  src={review.img}
                                  alt={`The rug ordered by ${review.nickname}`}
                                  loading='lazy'
                                />
                            }
                            <div className='p-4 w-full'>
                              <div className='flex flex-row space-x-2 items-center'>
                                <p className=' text-lg font-bold'>{review.nickname}</p>
                                <CheckBadgeIcon className='h-5 w-5 text-green-500' />
                              </div>
            
                              <div>
                                {
                                  review.rating !== "" && 
                                    <div className='flex flex-row space-x-1'>
                                      {
                                        [...Array(parseInt(review.rating))].map((e, i) => (
                                          <StarIcon key={i} className='h-5 w-5 text-black my-2' />
                                        ))
                                      }
                                    </div>
                                }
                              </div>
                              <p>{review.review}</p>
                            </div>
                          </div>
                        ))
                    }
                  </div>
                )
              })
            }

          </div>
        }

      <Footer />
    </main>
  );
}
