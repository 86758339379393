import React from "react";
import { useCartLine, useCart, Money } from "@shopify/hydrogen-react";
import { TrashIcon } from "@heroicons/react/24/solid";

import CartLineQuantityAdjust from "./cartLineQuantityAdjust";

export default function CartLineItem() {
  const { linesRemove } = useCart();
  const { id: lineId, quantity, merchandise, attributes, cost } = useCartLine();

  console.log(attributes);

  return attributes[1] && attributes[1].value && (
    <li key={lineId} className="flex">
      <div className="flex-shrink-0">
        <img
          src={attributes[1].value}
          className="object-cover object-center w-24 h-24 border rounded md:w-28 md:h-28"
          width={100}
          height={100}
          alt="Product"
        />
      </div>

      <div className="flex justify-between flex-1 ml-4 sm:ml-6">
        <div className="relative grid">
          <h3 className="font-medium">
            <a href={`/products/${merchandise.product.handle}`}>
              {merchandise.product.title}
            </a>
          </h3>

          <div className="flex flex-col justify-start">
            {(merchandise?.selectedOptions || []).map((option) => (
              <span key={option.name} className="last:mb-1 text-gray-500 text-sm">
                {option.name}: {option.value}
              </span>
            ))}
          </div>

          <div className="flex flex-row space-x-2 justify-between mb-1">
            <Money data={cost.totalAmount} className="text-lg" withoutTrailingZeros/>
          </div>

          <div className="flex items-center">
            <div className="flex justify-start text-copy mr-4">
              <CartLineQuantityAdjust
                lineId={lineId}
                quantity={quantity}
                linesRemove={linesRemove}
              />
            </div>
            <button
              type="button"
              onClick={() => linesRemove(lineId)}
              className="flex justify-center items-center text-xs text-red-500 hover:text-red-700 hover:underline overflow-hidden"
            >
              Remove
            </button>
          </div>
        </div>
        <span>
        </span>
      </div>
    </li>
  );
}