import React, {useEffect, useState, useRef} from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { AdvancedImage, AdvancedVideo, lazyload, responsive, placeholder } from '@cloudinary/react'
import { fill } from '@cloudinary/url-gen/actions/resize'
import ReactPixel from 'react-facebook-pixel'
import { CheckBadgeIcon, StarIcon, ChevronLeftIcon, ChevronRightIcon, TruckIcon, ScissorsIcon, PhoneIcon } from '@heroicons/react/24/solid'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import Navbar from '../ui-components/navbar'
import { cld } from '../utils/constants'
import Footer from '../ui-components/footer'
import Meta from '../utils/meta'

export default function Home() {
  const imageDiv1 = useRef(null);
  const imageDiv2 = useRef(null);

  const [linkToUse, setLinkToUse] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const IMG_SIZE = isMobile ? 70 : 100;
  const BADGE_SIZE = 100;

  useEffect(() => {
    if(localStorage && localStorage.getItem('user_image')) {
      setLinkToUse('/customize/editor')
    } else {
      setLinkToUse('/customize')
    }

    const options = {
      autoConfig: true,
      debug: true,
    }
    
    ReactPixel.init('2673093259367691', undefined, options);
    ReactPixel.pageView();

    let id;
    
    if(window._klOnsite) {
      id = setTimeout(() => {
        //window._klOnsite.openForm(['SY8t3N']);
      }, 5000);
    }

    return () => {
      clearTimeout(id);
    }
  }, [])

  return (
    <>
      <main className="w-full h-full bg-gray-100">
        <Meta 
          title='Custom Rugs - Rugtomize'
          description='Turn Any Image You Want Into a High-Quality Rug!'
        />

        <div className='w-full text-center bg-amber-400 text-gray-900 py-2 font-regular'>
          Sign Up to Our Newsletter and Get 15% OFF!
        </div>

        <a 
          href="tel:+19494784886"
          className='lg:hidden fixed bottom-5 right-5 rounded-full p-3 bg-amber-400'
        >
          <PhoneIcon className='h-7 w-7 text-white' />
        </a>
        
        <Navbar />

        <div className='w-full text-center bg-gray-100 py-2'>
          <div className='flex flex-row items-center justify-around w-full'>
            <div className='flex flex-row items-center space-x-2 lg:space-x-2'>
              <TruckIcon className='h-6 lg:h-9 w-6 lg:w-9 text-amber-500' />
              <div className='font-medium text-sm lg:text-lg'>Free Shipping</div>
            </div>

            <div className='flex flex-row items-center space-x-2 lg:space-x-2'>
              <ScissorsIcon className='h-6 lg:h-9 w-6 lg:w-9 text-amber-500' />
              <div className='font-medium text-sm lg:text-lg'>Hand-tailored in the USA</div>
            </div>


            <div className='hidden lg:flex flex-row items-center lg:space-x-2'>
              <CheckBadgeIcon className='h-5 lg:h-9 w-5 lg:w-9 text-amber-500' />
              <div className='font-medium text-xs lg:text-lg'>Satisfaction Guarantee</div>
            </div>
          </div>
        </div>

        <div className='flex flex-col items-center'>
          <div className='lg:hidden flex flex-col items-center w-full pt-3 space-y-3 bg-white'>
            <div className='flex flex-row items-center'>
              <AdvancedImage
                cldImg={cld.image('assets/images/stars_bani0m')}
                alt='Stars'
                width={80}
                height={80}
                className='ml-2'
              />
              <p className='md:text-lg ml-2 text-sm font-regular'>Over <span className='font-bold text-violet-500'>100+</span> Happy Customers!</p>
            </div>
            <h1 className='text-center text-3xl font-bold pb-1 text-gray-900 overflow-y-hidden'>The Original Custom Rug</h1>

            <div className='flex flex-row space-x-2'>
              <div className='flex border border-black rounded-lg'>
                <ReactCompareSlider
                  portrait
                  boundsPadding={0}
                  itemOne={
                    <ReactCompareSliderImage 
                      src={cld.image("assets/images/joerogan_csirro").toURL()} 
                      alt="Joe Rogan Experience logo" 
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage 
                      src={cld.image("assets/images/joerogancollab_ijldiv").toURL()} 
                      alt="Joe Rogan Experience logo on a custom rug build by Rugtomize" 
                    />
                  }
                  style={
                    {
                      width: "20vh",
                      height: "30vh",
                    }
                  }
                />
              </div>
              <div className='flex border border-black rounded-lg'>
                <ReactCompareSlider
                  portrait
                  boundsPadding={0}
                  itemOne={
                    <ReactCompareSliderImage 
                      src={cld.image("assets/images/monalisa_l8iv7x").toURL()} 
                      alt="The Mona Lisa" 
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage 
                      src={cld.image("assets/images/customrug_n3fwpf").toURL()} 
                      alt="The Mona Lisa on a custom rug build by Rugtomize" 
                    />
                  }
                  style={
                    {
                      width: "20vh",
                      height: "30vh",
                    }
                  }
                />
              </div>
            </div>
            
            <div className='flex bg-white pb-5 px-5'>
              <Link
                to={"customize/editor"}
                className="shadow-lg shadow-amber-400 border border-black border-opacity-5 rounded-lg bg-amber-400 px-5 py-2.5 text-xl lg:text-2xl font-semibold text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
              >
                {localStorage && localStorage.getItem('user_image') ? 'CONTINUE TO YOUR RUG' : 'CREATE YOUR CUSTOM RUG'}
              </Link>
            </div>
          </div>

          <div className='hidden bg-white lg:flex lg:flex-row lg:p-5 lg:items-center lg:justify-around lg:w-full'>
            <div className='pl-10 flex flex-col space-y-12'>
              <div>
                <div className='flex flex-row items-center'>
                  <AdvancedImage
                    cldImg={cld.image('assets/images/stars_bani0m')}
                    alt='Stars'
                    width={80}
                    height={80}
                    className='ml-2'
                  />
                  <p className='md:text-lg ml-2 text-sm font-regular'>Over <span className='font-extrabold text-violet-500'>100+</span> Happy Customers!</p>
                </div>
                <h1 className='text-4xl lg:text-5xl font-bold py-3 text-gray-900 overflow-y-hidden'>The Original Custom Rug</h1>
              </div>
              
              <div className='flex bg-white py-2'>
                <Link
                  to={"customize/editor"}
                  className="shadow shadow-amber-400 border border-black border-opacity-5 rounded-lg bg-amber-400 px-5 py-2.5 text-xl lg:text-2xl font-semibold text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
                >
                  {localStorage && localStorage.getItem('user_image') ? 'CONTINUE TO YOUR RUG' : 'CREATE YOUR CUSTOM RUG'}
                </Link>
              </div>
            </div>

            <div className='p-5'>
              <div className='flex flex-col space-y-2 items-center'>
                <div className='flex flex-row space-x-2 w-full'>
                  <div className='flex border border-black rounded-lg'>
                    <ReactCompareSlider
                      itemOne={
                        <ReactCompareSliderImage 
                          src={cld.image("assets/images/joerogan_csirro").toURL()} 
                          alt="Joe Rogan Experience logo" 
                        />
                      }
                      itemTwo={
                        <ReactCompareSliderImage 
                          src={cld.image("assets/images/joerogancollab_ijldiv").toURL()} 
                          alt="Joe Rogan Experience logo on a custom rug build by Rugtomize" 
                        />
                      }
                      style={
                        {
                          width: "40vh",
                          height: "35vh",
                        }
                      }
                      position={45}
                    />
                  </div>
                  <div className='flex border border-black rounded-lg'>
                    <ReactCompareSlider
                      itemOne={
                        <ReactCompareSliderImage 
                          src={cld.image("assets/images/monalisa_l8iv7x").toURL()} 
                          alt="The Mona Lisa" 
                        />
                      }
                      itemTwo={
                        <ReactCompareSliderImage 
                          src={cld.image("assets/images/customrug_n3fwpf").toURL()} 
                          alt="The Mona Lisa on a custom rug build by Rugtomize" 
                        />
                      }
                      style={
                        {
                          width: "40vh",
                          height: "35vh",
                        }
                      }
                      position={45}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col items-center justify-between lg:justify-center py-3 w-full h-full bg-blue-100'>
            <p className='text-sm lg:text-2xl lg:pr-20 py-1 font-semibold'>WE CREATED CUSTOM RUGS FOR</p>
            <div className="slider">
              <div className="slide-track">
                <div className='slide'>
                  <img src={cld.image('assets/images/joerogan_csirro').toURL()} alt="The Joe Rogan Experience Podcast Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/netflix_xwwbeu').toURL()} alt="Netflix Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/warnerbros_jcstil').toURL()} alt="Warner Bros Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/ntwrk_gokpkx').toURL()} alt="NTWRK Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/airforce_urvin3').toURL()} alt="Air Force Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/dove_yk2efb').toURL()} alt="Dove Logo" />
                </div>


                <div className='slide'>
                  <img src={cld.image('assets/images/joerogan_csirro').toURL()} alt="The Joe Rogan Experience Podcast Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/netflix_xwwbeu').toURL()} alt="Netflix Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/warnerbros_jcstil').toURL()} alt="Warner Bros Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/ntwrk_gokpkx').toURL()} alt="NTWRK Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/airforce_urvin3').toURL()} alt="Air Force Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/dove_yk2efb').toURL()} alt="Dove Logo" />
                </div>


                <div className='slide'>
                  <img src={cld.image('assets/images/joerogan_csirro').toURL()} alt="The Joe Rogan Experience Podcast Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/netflix_xwwbeu').toURL()} alt="Netflix Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/warnerbros_jcstil').toURL()} alt="Warner Bros Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/ntwrk_gokpkx').toURL()} alt="NTWRK Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/airforce_urvin3').toURL()} alt="Air Force Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/dove_yk2efb').toURL()} alt="Dove Logo" />
                </div>


                <div className='slide'>
                  <img src={cld.image('assets/images/joerogan_csirro').toURL()} alt="The Joe Rogan Experience Podcast Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/netflix_xwwbeu').toURL()} alt="Netflix Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/warnerbros_jcstil').toURL()} alt="Warner Bros Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/ntwrk_gokpkx').toURL()} alt="NTWRK Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/airforce_urvin3').toURL()} alt="Air Force Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/dove_yk2efb').toURL()} alt="Dove Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/joerogan_csirro').toURL()} alt="The Joe Rogan Experience Podcast Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/netflix_xwwbeu').toURL()} alt="Netflix Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/warnerbros_jcstil').toURL()} alt="Warner Bros Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/ntwrk_gokpkx').toURL()} alt="NTWRK Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/airforce_urvin3').toURL()} alt="Air Force Logo" />
                </div>

                <div className='slide'>
                  <img src={cld.image('assets/images/dove_yk2efb').toURL()} alt="Dove Logo" />
                </div>
              </div>
            </div>

            {/*<div className='flex flex-row items-center mt-4 lg:mt-0'>
              <AdvancedImage
                cldImg={cld.image('assets/images/joerogan_csirro').format("auto")}
                alt="The Joe Rogan Experience Podcast Logo"
                height={IMG_SIZE}
                width={IMG_SIZE}
                className='mx-3 lg:mx-5'
                plugins={[lazyload(), responsive(), placeholder()]}
              />

              <AdvancedImage
                cldImg={cld.image('assets/images/netflix_xwwbeu').format("auto")}
                alt="Netflix Logo"
                height={IMG_SIZE}
                width={IMG_SIZE}
                className='mx-3 lg:mx-5'
                plugins={[lazyload(), responsive(), placeholder()]}
              />

              <AdvancedImage
                cldImg={cld.image('assets/images/warnerbros_jcstil').format("auto")}
                alt='Warner Bros Logo'
                height={IMG_SIZE}
                width={IMG_SIZE}
                className='mx-3 lg:mx-5'
                plugins={[lazyload(), responsive(), placeholder()]}
              />
            </div>

            <div className='flex flex-row items-center mt-4 lg:mt-0'>
              <AdvancedImage
                cldImg={cld.image('assets/images/ntwrk_gokpkx').format("auto")}
                alt='NTWRK Logo'
                width={IMG_SIZE}
                height={IMG_SIZE}
                className='mx-3 lg:mx-5'
                plugins={[lazyload(), responsive(), placeholder()]}
              />

              <AdvancedImage
                cldImg={cld.image('assets/images/airforce_urvin3').format("auto")}
                alt='USA Air Force Logo'
                width={IMG_SIZE}
                height={IMG_SIZE}
                className='mx-3 lg:mx-5'
                plugins={[lazyload(), responsive(), placeholder()]}
              />

              <AdvancedImage
                cldImg={cld.image('assets/images/dove_yk2efb').format("auto")}
                alt='Dove Logo'
                width={IMG_SIZE}
                height={IMG_SIZE}
                className='mx-3 lg:mx-5'
                plugins={[lazyload(), responsive(), placeholder()]}
              />
            </div>*/}
          </div>

          {/*<div className='w-full mt-14'>
            <h1 className='text-3xl lg:text-5xl font-bold text-gray-900 text-center lg:overflow-y-hidden'>Celebrity Favorites</h1>
            <div className='flex flex-row justify-center align-middle overflow-x-scroll space-x-5 w-full py-4 px-2 mt-2 no-scrollbar'>
              {
                Array(6).fill().map((_, i) => (
                  <div key={i} className='shrink-0 w-[40vw] lg:w-[15vw] shadow-sm rounded-lg'>
                    <AdvancedVideo
                      cldVid={cld.video(`assets/video/Social${i+1}`).format("auto")}
                      cldPoster="auto"
                      autoPlay
                      muted
                      plugins={[lazyload()]}
                      loop
                    />
                  </div>
                ))
              }
            </div>
            
            <div className='flex flex-row justify-center align-middle overflow-x-scroll space-x-5 w-full px-2 mt-2 no-scrollbar'>
              {
                Array(6).fill().map((_, i) => (
                  <div key={i} className='shrink-0  w-[40vw] lg:w-[15vw] shadow-sm rounded-lg'>
                    <AdvancedVideo
                      cldVid={cld.video(`assets/video/Social${i+6}`).format("auto")}
                      cldPoster="auto"
                      autoPlay
                      muted
                      plugins={[lazyload()]}
                      loop
                    />
                  </div>
                ))
              }
            </div>
            </div>*/}

          <div className='lg:hidden w-full mt-14 flex flex-col items-center pb-5'>
            <div className='bg-gray-100'>
              <h1 className='text-3xl lg:text-5xl font-bold text-gray-900 text-center lg:overflow-y-hidden'>What Others Are Buying</h1>
              <p className='text-center font-light text-gray-500 text-sm px-5 py-2'>We help our customers bring out their personalities by customizing the rug in any way they prefer.</p>
            </div>

            <div className='flex flex-row overflow-x-scroll space-x-5 w-full px-2 mt-2 no-scrollbar'>
              {
                Array(4).fill().map((_, i) => (
                  <div key={i} className='shrink-0 w-44 shadow-sm rounded-lg'>
                    <AdvancedImage
                      cldImg={cld.image(`assets/images/business/${i+1}`).format("auto")}
                      alt={`Custom rugs with business theme for our customers.`}
                      plugins={[lazyload(), responsive(), placeholder()]}
                    />
                  </div>
                ))
              }

              {
                Array(4).fill().map((_, i) => (
                  <div key={i} className='shrink-0 w-44 shadow-sm rounded-lg'>
                    <AdvancedImage
                      cldImg={cld.image(`assets/images/art/${i+1}`).format("auto")}
                      alt={`Custom rugs with art theme for our customers.`}
                      plugins={[lazyload(), responsive(), placeholder()]}
                    />
                  </div>
                ))
              }
            </div>

            <div className='flex flex-row overflow-x-scroll space-x-5 w-full px-2 mt-2 no-scrollbar'>
              {
                Array(4).fill().map((_, i) => (
                  <div key={i} className='shrink-0 w-44 shadow-sm rounded-lg'>
                    <AdvancedImage
                      cldImg={cld.image(`assets/images/photo/${i+1}`).format("auto")}
                      alt={`Custom rugs with photo theme for our customers.`}
                      plugins={[lazyload(), responsive(), placeholder()]}
                    />
                  </div>
                ))
              }

              {
                Array(4).fill().map((_, i) => (
                  <div key={i} className='shrink-0 w-44 shadow-sm rounded-lg'>
                    <AdvancedImage
                      cldImg={cld.image(`assets/images/pet/${i+1}`).format("auto")}
                      alt={`Custom rugs with pet theme for our customers.`}
                      plugins={[lazyload(), responsive(), placeholder()]}
                    />
                  </div>
                ))
              }
            </div>

            <Link
              to="/customize/editor"
              className="shadow-lg shadow-amber-400 border border-black border-opacity-5 rounded-lg bg-amber-400 mt-4 px-10 py-2.5 text-xl font-semibold text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
            >
              CREATE YOUR RUG
            </Link>
          </div>

          <div className='hidden lg:flex lg:flex-row lg:justify-around lg:items-center lg:mt-20 lg:w-full'>
            <div className='h-full flex flex-col justify-center items-start space-y-4 pl-10 py-10'>
              <h1 className='text-5xl font-bold text-gray-900 w-full py-5'>What Others Are Buying</h1>
              <p className='font-light text-gray-500 text-2xl w-96 py-1'>We help our customers bring out their personalities by customizing the rug in any way they prefer.</p>
              <Link
                to="/customize/editor"
                className="shadow-lg shadow-amber-400 border border-black border-opacity-5 rounded-lg bg-amber-400 mt-4 px-10 py-2.5 text-xl font-semibold text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
              >
                CREATE YOUR RUG
              </Link>
            </div>

            <div className='w-1/2 relative'>
              <button 
                className='shadow-xl absolute top-1/2 left-1 bg-gray-400 rounded-full p-2'
                onClick={() => {
                  imageDiv1.current.scrollLeft -= 200
                  imageDiv2.current.scrollLeft -= 200
                }}
              >
                <ChevronLeftIcon className='h-7 w-7 text-white' />
              </button>

              <div ref={imageDiv1} className='scroll-smooth flex flex-row overflow-x-scroll space-x-5 w-full px-2 mt-2 no-scrollbar'>
                {
                  Array(4).fill().map((_, i) => (
                    <div key={i} className='shrink-0 w-56 shadow-sm rounded-lg'>
                      <AdvancedImage
                        cldImg={cld.image(`assets/images/business/${i+1}`).format("auto")}
                        alt={`Custom rugs with business theme for our customers.`}
                        plugins={[lazyload(), responsive(), placeholder()]}
                      />
                    </div>
                  ))
                }

                {
                  Array(4).fill().map((_, i) => (
                    <div key={i} className='shrink-0 w-56 shadow-sm rounded-lg'>
                      <AdvancedImage
                        cldImg={cld.image(`assets/images/art/${i+1}`).format("auto")}
                        alt={`Custom rugs with business theme for our customers.`}
                        plugins={[lazyload(), responsive(), placeholder()]}
                      />
                    </div>
                  ))
                }
              </div>

              <div ref={imageDiv2} className='scroll-smooth flex flex-row overflow-x-scroll space-x-5 w-full px-2 mt-2 no-scrollbar'>
                {
                  Array(4).fill().map((_, i) => (
                    <div key={i} className='shrink-0 w-56 shadow-sm rounded-lg'>
                      <AdvancedImage
                        cldImg={cld.image(`assets/images/photo/${i+1}`).format("auto")}
                        alt={`Custom rugs with business theme for our customers.`}
                        plugins={[lazyload(), responsive(), placeholder()]}
                      />
                    </div>
                  ))
                }

                {
                  Array(4).fill().map((_, i) => (
                    <div key={i} className='shrink-0 w-56 shadow-sm rounded-lg'>
                      <AdvancedImage
                        cldImg={cld.image(`assets/images/pet/${i+1}`).format("auto")}
                        alt={`Custom rugs with business theme for our customers.`}
                        plugins={[lazyload(), responsive(), placeholder()]}
                      />
                    </div>
                  ))
                }
              </div>

              <button 
                className='shadow-xl absolute top-1/2 right-1 bg-gray-400 rounded-full p-2'
                onClick={() => {
                  imageDiv1.current.scrollLeft += 200
                  imageDiv2.current.scrollLeft += 200
                }}
              >
                <ChevronRightIcon className='h-7 w-7 text-white' />
              </button>
            </div>
          </div>

          <div className='flex flex-col items-center justidy-center mt-16 w-full'>
            <div className='flex flex-row items-center justify-center'>
              <AdvancedImage
                cldImg={cld.image('assets/images/stars_bani0m').format("auto")}
                alt='Stars'
                width={80}
                height={80}
                className='ml-2'
                plugins={[lazyload(), responsive(), placeholder()]}
              />
              <p className='md:text-lg ml-2 text-sm font-semibold'>Over <span className='font-extrabold text-violet-500'>100+</span> Happy Reviews!</p>
            </div>
            <h1 className='text-3xl lg:text-5xl font-bold text-gray-900 text-center w-[150vh] lg:overflow-y-hidden'>Our Customers Love Us!</h1>

            <ul className="w-[90%] mt-[4vh] grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              <li className='col-span-1'>
                <div className='flex flex-col items-center bg-white rounded-lg shadow h-fit'>
                  <img
                    src="https://images.loox.io/uploads/2022/3/18/N1Do266Wh.jpg"
                    alt={`The rug ordered by Brett`}
                    loading='lazy'
                    className='w-full h-72 object-cover'
                  />
                  <div className='p-4 w-full'>
                    <div className='flex flex-row space-x-2 items-center'>
                      <p className=' text-lg font-bold'>Brett H.</p>
                      <CheckBadgeIcon className='h-5 w-5 text-green-500' />
                    </div>
  
                    <div>
                      <div className='flex flex-row space-x-1'>
                        {
                          [...Array(parseInt("5"))].map((e, i) => (
                            <StarIcon key={i} className='h-5 w-5 text-amber-400 my-2' />
                          ))
                        }
                      </div>
                    </div>
                    <p>This is insanely cool. Our son Hudson is 14 months and we decided to make a rug from him for him!</p>
                  </div>
                </div>
              </li>

              <li className='col-span-1'>
                <div className='flex flex-col items-center bg-white rounded-lg shadow h-fit'>
                  <img
                    src="https://images.loox.io/uploads/2023/3/9/CTjwzJtzF.jpg"
                    alt={`The rug ordered by Lineo`}
                    loading='lazy'
                    className='w-full h-72 object-cover'
                  />
                  <div className='p-4 w-full'>
                    <div className='flex flex-row space-x-2 items-center'>
                      <p className=' text-lg font-bold'>Lineo Y.</p>
                      <CheckBadgeIcon className='h-5 w-5 text-green-500' />
                    </div>
  
                    <div>
                      <div className='flex flex-row space-x-1'>
                        {
                          [...Array(parseInt("5"))].map((e, i) => (
                            <StarIcon key={i} className='h-5 w-5 text-amber-400 my-2' />
                          ))
                        }
                      </div>
                    </div>
                    <p>I went on the chat to make sure this site was really real, and they showed and proved that for sure, my wife surprised and overjoyed.</p>
                  </div>
                </div>
              </li>

              <li>
                <div className='flex flex-col items-center bg-white rounded-lg shadow h-fit'>
                  <img
                    src="https://images.loox.io/uploads/2023/9/8/mZZ7cZmQE.jpg"
                    alt={`The rug ordered by Francisco`}
                    loading='lazy'
                    className='w-full h-72 object-cover'
                  />
                  <div className='p-4 w-full'>
                    <div className='flex flex-row space-x-2 items-center'>
                      <p className=' text-lg font-bold'>Francisco S.</p>
                      <CheckBadgeIcon className='h-5 w-5 text-green-500' />
                    </div>
  
                    <div>
                      <div className='flex flex-row space-x-1'>
                        {
                          [...Array(parseInt("5"))].map((e, i) => (
                            <StarIcon key={i} className='h-5 w-5 text-amber-400 my-2' />
                          ))
                        }
                      </div>
                    </div>
                    <p>Man, I absolutely love my Rugtomize rug. Great quality and feels good. 👌 This rug will be used for my high end detailing studio for marketing and before my clients step into their vehicles. Great job!</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <Link
            to="/reviews"
            className="shadow-lg shadow-amber-400 border border-black border-opacity-5 rounded-lg bg-amber-400 mt-8 px-16 py-2.5 text-xl font-semibold text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
          >
            See All Reviews
          </Link>

          {/*<div className='flex flex-col lg:flex-row items-center justify-center mt-20'>
            <div className='relative px-5 text-center'>
              <h1 className='absolute m-auto left-0 right-0 top-4 text-white shadow-black font-bold text-2xl lg:text-3xl'>CUSTOM CIRCLE RUG</h1>
              <AdvancedImage
                cldImg={cld.image('assets/images/circle_yo03jr').roundCorners(byRadius(20)).resize(fill().width(400).height(400)).adjust(opacity(80))}
                alt='Custom circle rug with a cat. Created by Rugtomize'
                loading="lazy"
              />
              <Link
                to='/customize/editor?shape=circle'
                className="absolute shadow-lg shadow-amber-400 m-auto border border-black border-opacity-5 left-0 right-0 bottom-4 py-1.5 w-56 rounded-lg bg-amber-400 text-lg font-semibold text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
              >
                CREATE CIRCLE RUG
              </Link>
            </div>

            <div className='relative px-5 text-center mt-5 lg:mt-0'>
              <h1 className='absolute m-auto left-0 right-0 top-4 text-white text-shadow-sm shadow-black font-bold text-2xl lg:text-3xl'>CUSTOM RECTANGLE RUG</h1>
              <AdvancedImage
                cldImg={cld.image('assets/images/rectangle_qmjh19').roundCorners(byRadius(20)).resize(fill().width(400).height(400)).adjust(opacity(80))}
                alt='Custom rectangle rug. Created by Rugtomize'
                loading="lazy"
              />
              <Link
                to='/customize/editor?shape=rectangle'
                className="absolute shadow-lg shadow-amber-400 m-auto border border-black border-opacity-5 left-0 right-0 bottom-4 py-1.5 w-60 rounded-lg bg-amber-400 text-lg font-semibold text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
              >
                CREATE RECTANGLE RUG
              </Link>
            </div>

            <div className='relative px-5 text-center mt-5 lg:mt-0'>
              <h1 className='absolute m-auto left-0 right-0 top-4 text-white text-shadow-sm shadow-black font-bold text-2xl lg:text-3xl'>CUSTOM RUNNER RUG</h1>
              <AdvancedImage
                cldImg={cld.image('assets/images/runner_qgk7gs').roundCorners(byRadius(20)).resize(fill().width(400).height(400)).adjust(opacity(80))}
                alt='Custom rectangle rug. Created by Rugtomize'
                loading="lazy"
              />
              <Link
                to='/customize/editor?shape=runner'
                className="absolute shadow-lg shadow-amber-400 m-auto border border-black border-opacity-5 left-0 right-0 bottom-4 py-1.5 w-56 rounded-lg bg-amber-400 text-lg font-semibold text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
              >
                CREATE RUNNER RUG
              </Link>
            </div>
                      </div>*/}

          <div className='flex flex-col items-center justify-center mt-[10vh] w-full pb-5'>

            <h1 className='text-3xl lg:text-5xl font-bold text-gray-900 text-center w-[150vh] lg:overflow-y-hidden'>Our Core Values</h1>

            <div className="flex flex-col lg:flex-row space-y-2 lg:space-x-10 lg:space-y-0 w-full py-5 px-5 lg:px-20 mt-2 lg:mt-10">
              <div className="shadow bg-white flex flex-col items-center justify-center w-full rounded-xl py-5">
                <AdvancedImage
                  cldImg={cld.image('assets/images/fastshipping_hdpsb8').format("auto")}
                  alt='Fast Shipping Badge'
                  width={BADGE_SIZE}
                  height={BADGE_SIZE}
                  plugins={[lazyload(), responsive(), placeholder()]}
                />

                <h1 className='font-medium text-2xl text-gray-500 py-1'>Free and Fast Shipping</h1>
                <p className='font-light text-sm mt-2'>to all US Continental</p>
              </div>

              <div className="shadow bg-white flex flex-col items-center justify-center w-full rounded-xl py-5">
                <AdvancedImage
                  cldImg={cld.image('assets/images/satisfaction_mn1ulb').format("auto")}
                  alt='100% Satisfaction Guarantee badge'
                  width={BADGE_SIZE}
                  height={BADGE_SIZE}
                  plugins={[lazyload(), responsive(), placeholder()]}
                />

                <h1 className='font-medium text-2xl text-gray-500 w-56 text-center py-1'>100% Satisfaction Guarantee</h1>
                <p className='font-light text-sm text-center w-72 mt-2'>If you are not happy with the rug, we'll remake it until it turns out right!</p>
              </div>

              <div className="shadow bg-white flex flex-col items-center justify-center w-full rounded-xl py-5">
                <AdvancedImage
                  cldImg={cld.image('assets/images/handmade_zneonm').format("auto")}
                  alt='Handmade in the USA badge'
                  width={BADGE_SIZE}
                  height={BADGE_SIZE}
                  plugins={[lazyload(), responsive(), placeholder()]}
                />

                <h1 className='font-medium text-2xl text-gray-500 text-center py-1'>Hand Tailored In The USA</h1>
                <p className='font-light text-sm text-center w-72 mt-2'>Each rug is carefully crafted in Orange County, California</p>
              </div>
            </div>

            <Link
              to="/customize/editor"
              className="shadow-lg shadow-amber-400 border border-black border-opacity-5 rounded-lg bg-amber-400 mt-4 px-10 py-2.5 text-xl font-semibold text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
            >
              CREATE YOUR RUG
            </Link>
          </div>

          <div className=' mt-[10vh] w-full bg-white pb-10'>
            <h1 className='p-5 lg:p-0 lg:mt-5 text-3xl lg:text-5xl font-bold text-gray-900 self-start lg:overflow-y-hidden lg:text-center'>Discover More</h1>

            <div className='lg:hidden flex flex-row space-x-10 overflow-x-scroll px-4 no-scrollbar'>

              <div className='min-w-[70%]'>
                <Link to="/reviews">
                  <AdvancedImage
                    cldImg={cld.image('assets/images/banner_dwegqk').resize(fill().width(1000).height(1000)).format("auto")}
                    alt='Reviews'
                    className='pointer-events-none'
                    plugins={[lazyload(), responsive(), placeholder()]}
                  />
                  <div className='mt-1 p-1'>
                    <h2 className='font-bold text-gray-800 text-xl'>Customer Reviews</h2>
                    <p className='font-light'>People love our rugs!</p>
                  </div>
                </Link>
              </div>

              <div className='min-w-[70%]'>
                <Link to="/company/faq">
                  <AdvancedImage
                    cldImg={cld.image('assets/images/FAQ_dsrbfe').format("auto")}
                    alt='FAQ'
                    className='pointer-events-none'
                    plugins={[lazyload(), responsive(), placeholder()]}
                  />

                  <div className='mt-1 p-1'>
                    <h2 className='font-bold text-gray-800 text-xl'>FAQs</h2>
                    <p className='font-light'>Your questions answered</p>
                  </div>
                </Link>
              </div>

              <div className='min-w-[70%]'>
                <Link to="/company/aboutus">
                  <AdvancedImage
                    cldImg={cld.image('assets/images/ourstory_ctljyq').format("auto")}
                    alt='Our Story'
                    className='pointer-events-none'
                    plugins={[lazyload(), responsive(), placeholder()]}
                  />

                  <div className='mt-1 p-1'>
                    <h2 className='font-bold text-gray-800 text-xl'>Our Story</h2>
                    <p className='font-light'>Learn about us</p>
                  </div>
                </Link>
              </div>

              <div className='min-w-[70%]'>
                <Link to="/">
                  <AdvancedImage
                    cldImg={cld.image('assets/images/joerogancollab_ijldiv').resize(fill().width(1000).height(1000)).format("auto")}
                    alt='Joe Rogan Collab'
                    className='pointer-events-none transition ease-in dura hover:scale-110'
                    plugins={[lazyload(), responsive(), placeholder()]}
                  />

                  <div className='mt-1 p-1'>
                    <h2 className='font-bold text-gray-800 text-xl'>Celebrity Collaborations</h2>
                    <p className='font-light'>See who we worked with</p>
                  </div>
                </Link>
              </div>

            </div>

            <div className='hidden lg:flex flex-row space-x-10 items-center justify-center mt-10 px-4'>
              <div className='min-w-[20%] max-h-[70%]'>
                <Link to="/reviews">
                  <AdvancedImage
                    cldImg={cld.image('assets/images/banner_dwegqk').resize(fill().width(1000).height(1000)).format("auto")}
                    alt='Reviews'
                    className='pointer-events-none'
                    plugins={[lazyload(), responsive(), placeholder()]}
                  />
                  <div className='mt-1 p-1'>
                    <h2 className='font-bold text-gray-800 text-xl'>Customer Reviews</h2>
                    <p className='font-light'>People love our rugs!</p>
                  </div>
                </Link>
              </div>

              <div className='min-w-[20%] max-h-[70%]'>
                <Link to="/company/faq">
                  <AdvancedImage
                    cldImg={cld.image('assets/images/FAQ_dsrbfe').format("auto")}
                    alt='FAQ'
                    className='pointer-events-none'
                    plugins={[lazyload(), responsive(), placeholder()]}
                  />

                  <div className='mt-1 p-1'>
                    <h2 className='font-bold text-gray-800 text-xl'>FAQs</h2>
                    <p className='font-light'>Your questions answered</p>
                  </div>
                </Link>
              </div>

              <div className='min-w-[20%] max-h-[70%]'>
                <Link to="/company/aboutus">
                  <AdvancedImage
                    cldImg={cld.image('assets/images/ourstory_ctljyq').resize(fill().width(1000).height(1000)).format("auto")}
                    alt='Our Story'
                    className='pointer-events-none'
                    plugins={[lazyload(), responsive(), placeholder()]}
                  />

                  <div className='mt-1 p-1'>
                    <h2 className='font-bold text-gray-800 text-xl'>Our Story</h2>
                    <p className='font-light'>Learn about us</p>
                  </div>
                </Link>
              </div>

              <div className='min-w-[20%] max-h-[70%]'>
                <Link to="/">
                  <AdvancedImage
                    cldImg={cld.image('assets/images/joerogancollab_ijldiv').resize(fill().width(1000).height(1000)).format("auto")}
                    alt='Joe Rogan Collab'
                    className='pointer-events-none transition ease-in dura hover:scale-110'
                    plugins={[lazyload(), responsive(), placeholder()]}
                  />

                  <div className='mt-1 p-1'>
                    <h2 className='font-bold text-gray-800 text-xl'>Celebrity Collaborations</h2>
                    <p className='font-light'>See who we worked with</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

        </div>

        <Footer />
      </main>
    </>
  )
}
