import React from 'react'
import { AdvancedImage, AdvancedVideo } from '@cloudinary/react'
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity'
import { crop } from '@cloudinary/url-gen/actions/resize'
import { faces } from '@cloudinary/url-gen/qualifiers/focusOn'
import { fill } from '@cloudinary/url-gen/actions/resize'
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners'

import Meta from '../../../utils/meta'
import { cld } from '../../../utils/constants'
import Navbar from '../../../ui-components/navbar'
import Footer from '../../../ui-components/footer'

export default function Aboutus() {
  const aboutusImage = cld.image('assets/images/ourstory_ctljyq')
    .resize(
      crop().width(1920).height(980).gravity(focusOn(faces()))
    );

  const saman = cld.image('assets/images/Saman_q9ua6f');
  const sean = cld.image('assets/images/sean_z0cscr');
  const jahmeir = cld.image('assets/images/jahmeir_nazey0');
  const matt = cld.image('assets/images/matt_izwff8');
  const ryan = cld.image('assets/images/Ryan_vl5fmx');
  const sierra = cld.image('assets/images/sierra_on57tx');
  const ryano = cld.image('assets/images/ryani_zl6hwo');
  const mario = cld.image('assets/images/Mario_t78cyf');

  const aboutusVideo = cld.video('assets/video/AboutUs.Mobile_zjaicy');

  return (
    <main>
      <Meta
        title='About Us - Rugtomize'
        description='Learn about our company and the team behind it!'
      />

      <Navbar />

      <div className='w-full'>
        <AdvancedImage
          cldImg={aboutusImage}
          className="h-56 md:h-96 w-full object-cover"
          alt="The team at Rugtomize holding an award"
        />
        <div className='flex flex-col items-center space-y-5 mt-2 lg:hidden'>
          <div className='bg-amber-50 self-start rounded-r-2xl shadow p-3 mr-5'>
            <h2 className='font-bold text-xl'>Slogan</h2>
            <p>The original custom Rug company.</p>
          </div>

          <AdvancedImage
            cldImg={cld.image('assets/images/ab1_dfmsj5')}
            className="w-[80%] object-cover"
          />

          <div className='bg-amber-50 rounded-l-2xl shadow p-3 ml-5'>
            <h2 className='font-bold text-xl'>Mission</h2>
            <p>To innovate the process allowing limitless customization at competitive pricing with drastically reduced Lead-time. Cultivating ambiance through expressing identity.</p>
          </div>

          <AdvancedImage
            cldImg={cld.image('assets/images/ab2_ixrnmy')}
            className="w-[80%] object-cover"
          />

          <div className='bg-amber-50 rounded-r-2xl shadow p-3 mr-5'>
            <h2 className='font-bold text-xl'>Vision</h2>
            <p>Transcend the traditional rug into personal statements by representing individuals unique tastes and preferences</p>
          </div>

          <AdvancedImage
            cldImg={cld.image('assets/images/ab3_eeghnm')}
            className="w-[80%] object-cover"
          />

          <AdvancedImage
            cldImg={cld.image('assets/images/ab4_temwyu')}
            className="w-[80%] object-cover"
          />
        </div>

        <div className='hidden lg:flex lg:flex-col lg:items-center lg:justify-center lg:mt-10'>
          <div className='flex flex-row items-center justify-around w-full'>
            <div>
              <h2 className='font-bold text-5xl w-full h-16'>Slogan</h2>
              <p className='text-3xl'>The original custom Rug company.</p>
            </div>

            <div className='rounded-xl'>
              <AdvancedImage
                cldImg={cld.image('assets/images/ab1_dfmsj5').resize(fill().width(600).height(300))}
              />
            </div>
          </div>

          <div className='flex flex-row items-center justify-around w-full mt-44'>
            <div className='rounded-xl'>
              <AdvancedImage
                cldImg={cld.image('assets/images/ab2_ixrnmy').resize(fill().width(600).height(300))}
              />
            </div>

            <div className='w-[60vh]'>
              <h2 className='font-bold text-5xl w-full h-16'>Mission</h2>
              <p className='text-3xl'>To innovate the process allowing limitless customization at competitive pricing with drastically reduced Lead-time. Cultivating ambiance through expressing identity.</p>
            </div>
          </div>

          <div className='flex flex-row items-center justify-around w-full mt-44'>
            <div className='w-[60vh]'>
              <h2 className='font-bold text-5xl w-full h-16'>Vision</h2>
              <p className='text-3xl'>Transcend the traditional rug into personal statements by representing individuals unique tastes and preferences.</p>
            </div>

            <div className='flex flex-row items-center space-x-2'>
              <AdvancedImage
                cldImg={cld.image('assets/images/ab3_eeghnm').resize(fill().width(400).height(300))}
                className='rounded-xl'
              />

              <AdvancedImage
                cldImg={cld.image('assets/images/ab4_temwyu').resize(fill().width(400).height(300))}
                className='rounded-xl'
              />
            </div>
          </div>
        </div>
        <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900 mx-5 mt-16">The Company</h2>
      </div>

      <div className='mt-5 mx-5'>
        <p> Our goal is to pioneer and reinvent the industry. For centuries, rugs have been handmade and crafted by the traditional knot and weave method. After years of R & D an opportunity surfaced from the demand of customization to the limited selection of the existing market. We saw an opportunity in crafting unique, personalized rug. Tailored to the owners tastes & preferences.</p>
        <br />
        <p>We utilized 5 generations of experience, from the world-renown Persian rug industry, in order to re-create a process that meets the current market's shifting demands. Home decor created with craftsmanship, designed and tailored to clients’ personal tastes and preferences.  We identified the lack of customizable home ambience, so we decided to create a manufacturing process that unites our craft and platform with your personal customized creation.</p>
      </div>

      <div className='mt-10 flex justify-center'>
        <AdvancedVideo
          cldVid={aboutusVideo}
          autoPlay
          muted
          loop
          playsInline
          controls
          className='md:h-[40rem] md:w-[70rem] md:object-cover'
        />
      </div>

      <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900 mx-5 mt-[2vh]">The Team</h2>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-5 mt-5 mb-10'>
        <TeamMember
          name='Saman Kash'
          role='Analytics'
          image={saman}
        />

        <TeamMember
          name='Sean Azari'
          role='Marketing'
          image={sean}
        />

        <TeamMember
          name='Jahmeir Reed'
          role='Graphic Design'
          image={jahmeir}
        />

        <TeamMember
          name='Matt Craig'
          role='Operations'
          image={matt}
        />

        <TeamMember
          name='Ryan Oreif'
          role='Product Manager'
          image={ryan}
        />

        <TeamMember
          name="Sierra Runnels"
          role='Graphic Design Assistant'
          image={sierra}
        />

        <TeamMember
          name="Ryan O'Keef"
          role='Psychology'
          image={ryano}
        />

        <TeamMember
          name='Mario Leone'
          role='Advisor'
          image={mario}
        />
      </div>

      <Footer />
    </main>
  )
}

function TeamMember({ name, role, image }) {
  return (
    <div className='flex flex-col items-center justify-center'>
      <AdvancedImage
        cldImg={image}
        className="w-[70%] object-cover"
        alt={`${name} - ${role}`}
      />

      <div className='flex flex-col items-center justify-center mt-3'>
        <h3 className='text-xl font-bold'>{name}</h3>
        <p className='text-lg font-light'>{role}</p>
      </div>
    </div>
  )
}
