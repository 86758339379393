import React from 'react'
import {useCart} from '@shopify/hydrogen-react';
import ReactPixel from 'react-facebook-pixel';
import { AdvancedImage } from '@cloudinary/react';

import { cld } from '../../../utils/constants';
import { ArrowRightIcon, LockClosedIcon } from '@heroicons/react/24/solid';

export default function CartCheckoutActions() {
  const { checkoutUrl, lines} = useCart();

  lines.forEach((line) => {
    console.log(line);
  });

  return (
    <>
      <div className="flex flex-col items-center mt-1 md:mt-8">
        <a
          className="flex flex-col space-y-1 rounded-full font-medium text-center py-1 px-6 max-w-xl leading-none bg-amber-400 text-black w-full"
          href={checkoutUrl}
          onClick={() => {
            ReactPixel.track('InitiateCheckout');
          }}
        >
          <div className='p-0.5 flex flex-row space-x-1 items-center justify-center text-gray-600'>
            <LockClosedIcon className='w-3 h-3' />
            <p className='text-[10px]'>100% Secure Payment Processing</p>
          </div>
          <div className='flex flex-row space-x-1 items-center justify-center'>
            <div className='font-bold text-lg'>Checkout</div>
            <ArrowRightIcon className="w-4 h-4" />
          </div>
        </a>

        <AdvancedImage
          cldImg={cld.image('assets/images/paymethods_rssxmv')}
          className="mt-2"
          width="400"
        />
      </div>
    </>
  );
}
