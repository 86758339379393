import React, {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';

export default function Customize() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`${pathname}/upload`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <></>
  )
}
