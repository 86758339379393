import React from 'react'
import { useCart, Money } from "@shopify/hydrogen-react";

export default function CartSummary() {
  const { cost, discountCodes } = useCart();

  console.log(cost);

  return (
    <>
      <dl className="space-y-2">
        <div className="flex items-center justify-between">
          <dt className='font-bold'>Total</dt>
          <dd>
            {cost ? (
              discountCodes.length > 0 && cost.totalAmount.amount < cost.subtotalAmount.amount ?
                <div className='flex flex-row items-center space-x-2'>
                  <Money className='line-through' data={cost.subtotalAmount} />
                  <Money className='text-green-500 font-bold' data={cost.totalAmount} />
                </div>
              :
                <Money data={cost.totalAmount} />
            ) : (
              "-"
            )}
          </dd>
        </div>

        {
          discountCodes.length === 0 || cost.totalAmount.amount === cost.subtotalAmount.amount ?
            <div className="flex items-center justify-between">
              <dt className='font-bold'>Discounts</dt>
              <dd>No discounts applied</dd>
            </div>
          :
            discountCodes.map((discountCode) => {
              return (
                <div className="flex items-center justify-between" key={discountCode.code}>
                  <dt className='font-bold'>Discount</dt>
                  <dd>{discountCode.code}<span className='text-green-500'> applied</span></dd>
                </div>
              );
            }
        )}
      </dl>
    </>
  );
}