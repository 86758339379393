import React from 'react'
import { AdvancedImage } from '@cloudinary/react'

import { cld } from '../../../utils/constants'
import Meta from '../../../utils/meta'
import Navbar from '../../../ui-components/navbar'
import Footer from '../../../ui-components/footer'

export default function Care() {
  return (
    <main>

      <Meta
        title='How to clean your Custom Rug - Rugtomize'
        description='How to clean your custom rug from Rugtomize'
      />

      <Navbar />

      <div className='w-full'>
        <AdvancedImage
          cldImg={cld.image('assets/images/care_vujkah')}
          className="h-56 md:h-96 w-full object-cover"
          alt="Person holding a rug with a question mark"
        />
      </div>

      <div className='w-full bg-gray-50 px-4 mb-10'>
        <h1 className='font-bold text-3xl text-center py-5 text-gray-900'>Care and Maintenance</h1>

        <p className='text-lg'>To keep your rug clean, it is best to <strong>vacuum</strong> as needed.</p>
        <br />
        <p className='text-lg'>For small stains we recommend cleaning with a <strong>damp cloth</strong> or with a <strong>rug spot-cleaning solution</strong>. Getting your rug <strong>cleaned by a professional</strong> may be necessary for any difficult stains you can't remove on your own.</p>
        <br />
        <p className='text-lg'>To maintain the quality of your rug it would be best to <strong>avoid</strong> using <strong>bleach</strong> or <strong>steam</strong> and make sure you <strong>air dry your rug after</strong> any thorough cleanings.</p>
      </div>

      <Footer />
    </main>
  )
}
