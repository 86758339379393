import React from 'react'

import Meta from '../../../utils/meta'
import Navbar from '../../../ui-components/navbar'
import Footer from '../../../ui-components/footer'

export default function Refund() {
  return (
    <main>
      <Meta
        title='Refund Policy - Rugtomize'
        description='Refund policy for Rugtomize and our custom rugs'
      />

      <Navbar />
      <h1 className='text-center font-bold text-2xl mt-10'>Refund Policy</h1>

      <div class="mx-4 mt-10 mb-[5vh]">
        <p class="header-title" _ngcontent-c21=""><strong>ORDER, SHIPPING AND RETURN/REFUND POLICY</strong></p>
        <p _ngcontent-c21="">Except as otherwise provided elsewhere in this Agreement or on the Platform, when you post comments or reviews to the Platform, you also grant us the right to use the name that you submit with any review, comment, or other Content, if any, in connection with such review, comment, or other content. You represent and warrant that you own or otherwise control all of the rights to the reviews, comments and other Content that you post on the Platform and that use of your reviews, comments, or other Content by us will not infringe upon or violate the rights of any third party. You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any Submissions or Content. We may, but shall not be obligated to, remove or edit any Submissions (including comments or reviews) for any reason.</p>
        <p _ngcontent-c21="">Once an order has been released for shipping, it cannot be cancelled. Please refer to our returns policy below for additional details. All cancellation requests must be submitted via email to<span>&nbsp;</span><span _ngcontent-c21=""><a href="mailto:INFO@rugtomize.us?" target="_top" _ngcontent-c21="">INFO@rugtomize.com</a></span><span>&nbsp;</span>. Cancellation is confirmed only when Rugtomize notifies that the order has been canceled via email. Cancellation requests require:</p>
        <p _ngcontent-c21="">-- Customer Number (alphanumeric value)<br _ngcontent-c21="" />-- Item Number (if only some items are being canceled in an order with multiple items)<br _ngcontent-c21="" />-- Order Number</p>
        <p _ngcontent-c21="">Cancellations are not permitted for custom/made-to-order products once the order is acknowledged by Rugtomize.<br /><br /></p>
        <p class="header-title-under" _ngcontent-c21=""><strong>Backorders</strong></p>
        <p _ngcontent-c21="">Any statements Rugtomize makes concerning a date or date range that a backordered product is expected to arrive is an estimate of a shipment’s arrival time at Rugtomize’s warehouse only, and no guarantee is made as to when backorders will ship.<br /><br /></p>
        <p class="header-title-under" _ngcontent-c21=""><strong>Shipping</strong></p>
        <p _ngcontent-c21="">All shipments are FOB Rugtomize’s warehouse. We reserve the right to use our own discretion as to the carrier to be used on any shipment. We will exercise reasonable efforts to use the most cost-effective carrier. Most orders ship regular parcel ground service unless deemed to be non-parcel based upon the order size or shipping characteristics. Additional charges apply for expedited service. Freight rates are calculated based on weight, dimensions, ship location (residential/non-residential), and quantity. Once shipped, all cost of returning the goods is the responsibility of the customer.<br /><br /></p>
        <p class="header-title-under" _ngcontent-c21=""><strong>Goods Damaged in Shipping</strong></p>
        <p _ngcontent-c21="">All packages must be inspected by the customer upon arrival and any damage claims must be submitted to the carrier. Rugtomize will not accept claims and is not responsible for any damage that occurred during shipping.<br /><br /></p>
        <p class="header-title-under" _ngcontent-c21=""><strong>Returns</strong></p>
        <p _ngcontent-c21=""><u _ngcontent-c21="">Non-Defective Non-Custom Rugs</u>. No returns will be accepted without Rugtomize’s prior approval, which approval is completely within Rugtomize’s discretion. Rugtomize’s approval of a return is reflected by Rugtomize issuing a Return Merchandise Authorization (“RMA”) number to customer. RMA forms can be obtained by contacting<span>&nbsp;</span><span _ngcontent-c21=""><a href="mailto:INFO@rugtomize.us?" target="_top" _ngcontent-c21="">INFO@rugtomize.com</a></span><span>&nbsp;</span>and must be returned by email to<span>&nbsp;</span><span _ngcontent-c21=""><a href="mailto:INFO@rugtomize.us?" target="_top" _ngcontent-c21="">INFO@rugtomize.com</a></span>. All non-defective returns that are accepted by Rugtomize through issuance of an RMA number and returned in salable condition will be credited to customer's original method of payment. All return shipping charges will be the responsibility of the customer. All unauthorized returns will be refused and returned at sender's expense.<span>&nbsp;</span><strong _ngcontent-c21="">Rugtomize will not accept returned merchandise received more than 7 days after delivery. There is a 25% restocking fee on all valid returns + shipping</strong>.</p>
        <p _ngcontent-c21=""><u _ngcontent-c21="">Custom Rugs</u>.<span>&nbsp;</span><strong _ngcontent-c21="">No returns, cancellations or refunds are permitted on custom/made-to-order designed rugs once the order is acknowledged by Rugtomize, except in the case there is a manufacturing defect.</strong></p>
        <p _ngcontent-c21=""><u _ngcontent-c21="">Defective Goods</u>. All shipments should be inspected immediately upon arrival and any defects must be reported immediately to Rugtomize via a Return Merchandise Authorization form submitted to<span>&nbsp;</span><span _ngcontent-c21=""><a href="mailto:INFO@rugtomize.us?" target="_top" _ngcontent-c21="">INFO@rugtomize.com</a></span>. Upon approval of the RMA form by Rugtomize, Rugtomize will process an order for refund or replacement and issue an RMA number and call tag for the defective merchandise. Replacements are issued only upon request and will require a new order to be placed. If replacement is not requested, a full credit will be issued to the original invoice. Credit will be applied after Rugtomize receives the defective item and verifies the existence of the defect.<span>&nbsp;</span><strong _ngcontent-c21="">Rugtomize will accept a defect claim only within 7 days of the date of delivery<span>&nbsp;</span></strong>. Customer waives any defect claim that is not properly reported within 7 days of the date of delivery. If upon return Rugtomize reasonably determines that damage is due to usage and not a manufacturing defect, the customer will be responsible for the cost of the rug plus all shipping charges.<br /><br /></p>
        <p class="header-title-under" _ngcontent-c21=""><strong>Product Characteristics</strong></p>
        <p _ngcontent-c21="">Handmade rugs may have slight variation in design, color and size (3%-5%) which are not considered defects. Shedding and/or sprouting also are considered normal, and will vary depending on the construction and content. Machine woven rugs may have slight variation in size (up to 5%) which are not considered defects. Custom made rugs may have variations in color and design from the submitted file. Rugtomize will use reasonable efforts to match the color and design of the submitted electronic file, but can give no assurances that the final rug will be identical to the submitted design.<br /><br /></p>
        <p class="header-title-under" _ngcontent-c21=""><strong>LIMITATION OF LIABILITY</strong></p>
        <p _ngcontent-c21="">UNDER NO CIRCUMSTANCES WILL RUGTOMIZE, ITS AFFILIATES OR AGENTS BE LIABILE FOR: (A) ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, WHICH INCLUDES BUT IS NOT LIMITED TO, LOSS OF PROFITS, REVENUES OR SAVINGS, WHETHER A CLAIM FOR ANY LIABILITY IS PREMISED UPON BREACH OF CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY OF LIABILITY; OR (B) CLAIMS, DEMANDS OR ACTIONS AGAINST THE CUSTOMER BY ANY THIRD PARTY. NOTWITHSTANDING THE FOREGOING, IN THE EVENT OF ANY LIABILITY INCURRED BY RUGTOMIZE, RUGTOMIZE’S ENTIRE LIABILITY FOR DAMAGES FROM ANY CAUSE WHATSOEVER WILL NOT EXCEED THE DOLLAR AMOUNT PAID BY CUSTOMER FOR THE GOOD(S) GIVING RISE TO THE CLAIM.<br /><br /></p>
        <p class="header-title-under" _ngcontent-c21=""><strong>Miscellaneous</strong></p>
        <p _ngcontent-c21="">All transactions and any contract or contractual relationship arising from the use of the Platform will be governed by the laws of the State of California. The state courts of Los Angeles County, California, and the federal courts of the Central District of California will share exclusive jurisdiction over any legal action arising from a dispute in connection with any transaction, contract or contractual relationship arising from the use of the Platform.</p>
      </div>

      <Footer />
    </main>
  )
}
