import React from 'react'
import { Link } from 'react-router-dom'

import Meta from '../utils/meta'
import Navbar from '../ui-components/navbar'


export default function PageNotFound() {
  return (
    <div className='w-full h-[calc(100dvh)]'>
      <Meta 
        title='Cart - Rugtomize'
        description='Manage your cart and checkout!'
      />
      <Navbar />

      <div className='flex flex-col w-screen h-screen items-center justify-center space-y-5'>
        <span className='items-center place-center font-bold text-2xl'>404 - Page Not Found!</span>
        <Link
            to="/"
            className="shadow rounded-lg bg-amber-400 drop-shadow-2xl px-16 py-2.5 text-xl font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
        >
            Go to Home
        </Link>
      </div>
    </div>
  )
}
