import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';
import './App.css';
import reportWebVitals from './reportWebVitals';
import {ShopifyProvider, CartProvider} from '@shopify/hydrogen-react';

import Meta from './utils/meta';
import Home from './pages';
import Cart from './pages/cart';
import Customize from './pages/home';
import Upload from './pages/home/upload';
import Aboutus from './pages/company/aboutus';
import Contact from './pages/company/contact';
import FAQ from './pages/company/faq';
import Terms from './pages/legal/terms';
import Privacy from './pages/legal/privacy';
import Refund from './pages/legal/refund';
import Howitsmade from './pages/company/howitsmade';
import Reviews from './pages/company/reviews';
import Care from './pages/rug/care';
import ScrollToTop from './utils/scrollToTop';
import PageNotFound from './pages/404';
import TagManager from 'react-gtm-module';

const EditorComponent = lazy(() => import('./pages/home/editor'));
const renderLoader = () => <p>Loading</p>;

const router = createBrowserRouter([
  {
    path: '*',
    element: <Navigate to="/404" />
  },
  {
    path: '/404',
    element: <PageNotFound />
  },
  {
    path: '/',
    element: <Home />
  },
  {
    path: "/reviews",
    element: <Reviews />
  },
  {
    path: '/customize',
    children: [
      {
        index: true,
        element: <Customize />
      },
      {
        path: 'upload',
        element: <Upload />
      },
      {
        path: 'editor',
        element: (
          <Suspense fallback={renderLoader()}>
            <EditorComponent />
          </Suspense>
        )
      }
    ]
  },
  {
    path: '/cart',
    element: <Cart />
  },
  {
    path: '/company',
    children: [
      {
        path: 'aboutus',
        element: <Aboutus />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: 'faq',
        element: <FAQ />
      },
      {
        path: 'howitsmade',
        element: <Howitsmade />
      }
    ]
  },
  {
    path: '/rug',
    children: [
      {
        path: 'care',
        element: <Care />
      }
    ]
  },
  {
    path: '/legal',
    children: [
      {
        path: 'refundpolicy',
        element: <Refund />
      },
      {
        path: 'privacypolicy',
        element: <Privacy />
      },
      {
        path: 'termsofservice',
        element: <Terms />
      }
    ]
  }
]);

const tagManagerArgs = {
  gtmId: "G-EVHK9V9F4Y"
}

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Meta 
      title='Custom Rugs - Rugtomize'
      description='Shop our high-quality customizable rugs with your favorite images!'
    />
    <ShopifyProvider
      storeDomain={process.env.REACT_APP_RUGTOMIZE_URL}
      storefrontToken={process.env.REACT_APP_RUGTOMIZE_PUBLIC}
      storefrontApiVersion={process.env.REACT_APP_SHOPIFY_API_VERSION}
      countryIsoCode="IT"
      languageIsoCode="EN"
    >
      <CartProvider>
        <RouterProvider router={router} >
          <ScrollToTop  />
        </RouterProvider>
      </CartProvider>
    </ShopifyProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
