import { fabric } from "fabric-with-gestures"
import { Cloudinary } from '@cloudinary/url-gen';

export const API_URL = process.env.NODE_ENV === "development" ? 
  "http://localhost:8080" : 
  "https://rugtomize-backend-production.up.railway.app"

export const cld = new Cloudinary({
  cloud: {
    cloudName: "dwuhzdfjh"
  }
});

const manualcropGif = cld.image('assets/gifs/manualcrop_cjyumu').toURL();
const manualresizeGif = cld.image('assets/gifs/manualresize_ktilkh').toURL();
const autofitImage = cld.image('assets/images/autofit_jcsjuc').toURL();
const borderImage = cld.image('assets/images/border_ha1obb').toURL();

export const DEFAULT_SELECTED_RUG_OPTIONS = {
  id: "",
  type: "",
  title: "",
  image: "",
  mockupCroppingType: "manual_crop",
  quantity: 1,
  notes: "",
  price: "",
  scale: 1,
}

export const DEFAULT_CLIP_PATHS = {
  rectangle: {
    "2'x3'": {
      width: 1014.5,
      height: 670,
    },
    "3'x5'": {
      width: 1270,
      height: 757.8,
    },
    "5'x7'": {
      width: 1490,
      height: 930,
    },
    "8'x10'": {
      width: 1830,
      height: 1450,
    }
  },
  runner: {
    "2.5'x7'": {
      width: 540,
      height: 1530,
    },
    "2.5'x10'": {
      width: 446,
      height: 1780,
      top: -60,
      left: -3
    }
  },
  circle: {
    "3'x3'": {
      radius: 401,
      top: -5,
    },
    "4'x4'": {
      radius: 519,
      top: -4,
    },
    "6'x6'": {
      radius: 591.1,
      top: -1
    },
    "8'x8'": {
      radius: 802,
      top: -3
    }
  }
}

export const DEFAULT_TUTORIAL_STEPS = {
  0: {
    type: "description",
    description: "There are different options to customize your Rug with your own image. Let's check them out!"
  },
  1: {
    type: "autofit",
    description: "Use the Auto Fit option to automatically resize the image to fit the rug. This will ignore the aspect ratio of the image.",
    image: autofitImage
  },
  2: {
    type: "border",
    description: "Use the Keep Aspect Ratio option to resize the image while keeping the aspect ratio of the image. If the image is too small, it will be centered inside the rug and the rest of the rug will be the average color of the image.",
    image: borderImage
  },
  3: {
    type: "manualcrop",
    description: "Use the Manual Crop option to manually crop the image inside the rug.",
    image: manualcropGif
  },
  4: {
    type: "manualresize",
    description: "Use the Manual Resize option to manually resize the image inside the rug. Use the handles on the corners of the image to resize it and the top one to rotate.",
    image: manualresizeGif
  }
}

export function getClipPath(typeOfRug, dimension) {
  console.log(dimension)
  switch (typeOfRug) {
    case "rectangle":
      return new fabric.Rect({
        width: DEFAULT_CLIP_PATHS.rectangle[dimension].width,
        height: DEFAULT_CLIP_PATHS.rectangle[dimension].height,
        originX: "center",
        originY: "center",
        /*scaleX: DEFAULT_CLIP_PATHS.rectangle[dimension].width * scale,
        scaleY: DEFAULT_CLIP_PATHS.rectangle[dimension].height * scale,*/
      });

    case "circle":
      return new fabric.Circle({
        radius: DEFAULT_CLIP_PATHS.circle[dimension].radius,
        originX: "center",
        originY: "center",
        top: DEFAULT_CLIP_PATHS.circle[dimension].top || 0,
      });

    case "runner":
      return new fabric.Rect({
        width: DEFAULT_CLIP_PATHS.runner[dimension].width,
        height: DEFAULT_CLIP_PATHS.runner[dimension].height,
        originX: "center",
        originY: "center",
        top: DEFAULT_CLIP_PATHS.runner[dimension].top || 0,
        left: DEFAULT_CLIP_PATHS.runner[dimension].left || 0,
      });
    
    default:
      return new fabric.Rect({
        width: 100,
        height: 100,
        originX: "center",
        originY: "center",
      });
  }

}

export function getAverageRGB(fabricImg) {
  let blockSize = 5, // only visit every 5 pixels
    defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
    canvas = document.createElement('canvas'),
    context = canvas.getContext && canvas.getContext('2d'),
    data, width, height,
    i = -4,
    length,
    rgb = {r:0,g:0,b:0},
    count = 0;

  if (!context) {
    return defaultRGB;
  }

  height = canvas.height = fabricImg.naturalHeight || fabricImg.offsetHeight || fabricImg.height;
  width = canvas.width = fabricImg.naturalWidth || fabricImg.offsetWidth || fabricImg.width;

  let htmlImg = document.createElement("img");
  htmlImg.src = fabricImg.getSrc();

  context.drawImage(htmlImg, 0, 0);

  try {
      data = context.getImageData(0, 0, width, height);
  } catch(e) {
      /* security error, img on diff domain */
      return defaultRGB;
  }

  length = data.data.length;

  while ( (i += blockSize * 4) < length ) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i+1];
    rgb.b += data.data[i+2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r/count);
  rgb.g = ~~(rgb.g/count);
  rgb.b = ~~(rgb.b/count);

  return rgb;
}

export function getRugImage(typeOfRug, dimension) {
  return cld.image(`assets/rugs/${typeOfRug}/${dimension}`).toURL();
}

function rand() {
  return Math.random().toString(36).substr(2);
}

export function token() {
  return rand() + rand();
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function executedFunction() {
    let context = this;
    let args = arguments;
    
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    
    let callNow = immediate && !timeout;
    
    clearTimeout(timeout);
    
    timeout = setTimeout(later, wait);
    
    if (callNow) func.apply(context, args);
  };
}