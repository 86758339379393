import React, {useState} from 'react'
import { useCart} from '@shopify/hydrogen-react'


export default function CartDiscount({discountCode, setDiscountCode}) {
  const { discountCodesUpdate } = useCart();

  const [discountCodeError, setDiscountCodeError] = useState(false);

  const updateDiscountCode = (discountCode) => {
    if (discountCode !== '') {
      discountCodesUpdate(discountCode);
    } else {
      setDiscountCodeError(true);
    }
  }

  return (
    <div className='mb-4 pb-2 border-b'>
        <div className='font-bold text-sm text-gray-800 pt-2'>HAVE A DISCOUNT CODE?</div>

        <div className='flex flex-row items-center w-full'>
          <input
            className='w-56 m-1 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 border rounded-lg ring-0 outline-1 focus:outline-amber-400 focus:ring-amber-400'
            type='text'
            value={discountCode}
            placeholder='Enter discount code'
            onChange={(e) => {
              setDiscountCode(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                updateDiscountCode(discountCode);
              }
            }}
          />

          <button
            className='px-2 py-2 ml-2 w-20 text-md text-center font-light text-black bg-amber-400 rounded-lg hover:bg-amber-300 focus:outline-none focus:bg-amber-500'
            type='button'
            onClick={() => updateDiscountCode(discountCode)}
          >
            Apply
          </button>

          {discountCodeError && (
            <div className='text-sm text-red-500 ml-2'>
              Invalid discount code
            </div>
          )}
        </div>
    </div>
  )
}
