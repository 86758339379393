import { API_URL } from "../utils/constants";

export async function uploadImageToCloudinary(image, publicId, folder) {
  const result = await fetch(`${API_URL}/upload-image-to-cloudinary`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      image: image,
      publicId: publicId,
      folder: folder
    })
  });

  return await result.json();
}

export async function compressImage(data) {
  const result = await fetch(`${API_URL}/compress-image`,
  {
    method: 'POST',
    body: data
  });

  return await result.blob();
}

export async function subscribeToKlaviyo(email) {
  const result = await fetch(`${API_URL}/subscribe-to-klaviyo`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email
    })
  });

  return await result.json();
}