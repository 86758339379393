import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { AdvancedImage } from '@cloudinary/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useDropzone } from 'react-dropzone';
import ReactPixel from 'react-facebook-pixel';
import imageCompression from 'browser-image-compression';

import { cld } from '../../utils/constants';
import Meta from '../../utils/meta';
import { compressImage } from '../../services/backendService';
import Navbar from '../../ui-components/navbar';
import LoadingModal from './components/loadingModal';
import Modal from '../../ui-components/modal';
import classNames from 'classnames';

export default function Upload() {
  const navigate = useNavigate();
  const onDrop = useCallback(acceptedFile => {
    handleFileChange(acceptedFile[0]);
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true})

  const [userLoadedImage, setUserLoadedImage] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState({
    open: false,
    message: "none",
    status: "none"
  });
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [selectedExamples, setSelectedExamples] = useState("Business");

  const exampleDivRef = useRef(null);

  const handleFileChange = async (e) => {
    console.log(e);
    const file = e;

    if(file.size > 20000000) {
      setShowErrorModal(true);
      return;
    }

    setShowLoadingModal({
      open: true,
      message: 'Compressing image...',
      status: 'loading'
    });

    const orientation = await imageCompression.getExifOrientation(file);
    const options = {
      alwaysKeepResolution: true,
      exifOrientation: orientation
    };

    const correctlyOrientedImage = await imageCompression(file, options);

    const data = new FormData();
    data.append('image', correctlyOrientedImage);
    const compressedImage = await compressImage(data);

    const reader = new FileReader();
    reader.readAsDataURL(compressedImage);

    reader.addEventListener("load", function () {
      localStorage.setItem("user_image", reader.result);
      setUserLoadedImage(reader.result);
    });
  };

  useEffect(() => {
    if(userLoadedImage) navigate('/customize/editor');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoadedImage]);

  useEffect(() => {
    ReactPixel.track('ViewContent', {
      content_name: 'Upload Page',
    });
  }, []);

  const image = cld.image("assets/images/customrug_n3fwpf");

  return (
    <main className='w-full h-full'>
      <Meta 
        title='Upload - Rugtomize'
        description='Select and upload your favorite image to preview and customize it on the rug'
      />

      <Navbar />

      <LoadingModal
        open={showLoadingModal}
        setOpen={setShowLoadingModal}
      />

      <Modal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        title="Error"
        type="error"
        message="Your image file is too big! Max size allowed is 20MB."
        actionText="Upload another image"
        Icon={XMarkIcon}
      />

      <div className='lg:hidden flex flex-col items-center mt-10 h-screen bg-gray-100'>
        <AdvancedImage
          cldImg={image}
          className="h-44 lg:h-96 w-[90%] object-cover rounded-lg"
          alt="A custom rug made by Rugtomize with the Monna Lisa on it in a living room"
        />

        {
          !userLoadedImage && 
            <div className='flex flex-col items-center mt-[5vh]'>
              <h1 className='font-bold text-3xl text-gray-900'>Create Your Custom Rug</h1>
              <p className='text-center mx-10 mt-[1vh] text-gray-500'>Upload any photo you want to preview it on your rug and customize it.</p>

              <button
                className="shadow-lg shadow-amber-400 border border-black border-opacity-5 mt-[5vh] rounded-lg bg-amber-400 px-16 py-2.5 text-2xl font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
                onClick={() => document.getElementById('file-upload').click()}
              >
                UPLOAD IMAGE
              </button>

              <p className='mt-[1vh]'>or</p>

              <button
                className="mt-[1vh] border border-gray-200 rounded-lg bg-white px-16 py-2.5 text-lg font-medium text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                onClick={() => exampleDivRef.current.scrollIntoView({ behavior: 'smooth' })}
              >
                See Examples
              </button>
            </div>
        }
      </div>

      <div className='hidden lg:flex bg-gray-100 flex-row items-center justify-between w-full h-[85vh] px-20'>
        <div className='flex flex-col space-y-10'>
          <AdvancedImage
            cldImg={image}
            className="h-44 lg:h-52 w-[70%] object-cover rounded-lg"
            alt="A custom rug made by Rugtomize with the Monna Lisa on it in a living room"
          />

          <h1 className='font-bold text-7xl text-gray-900 w-[70%] h-auto overflow-y-hidden py-1'>Create Your Own Custom Rug</h1>
          <p className='mt-[1vh] text-xl text-gray-500'>Upload any photo you want to preview it on your rug and customize it.</p>
        </div>

        <div className='flex flex-col items-center justify-center shadow-2xl shadow-amber-400 rounded-3xl'>
          <div className='flex flex-col justify-center items-center bg-white w-[60vh] h-[50vh]' {...getRootProps()}>
            <input 
              id="file-upload" 
              name="file-upload" 
              type="file" 
              className="sr-only"
              onChange={handleFileChange}
              {...getInputProps()}
              accept=".jpg,.jpeg,.png,.webp"
            />
            
            <button
              className="shadow-lg shadow-amber-400 border border-black border-opacity-5 rounded-lg bg-amber-400 px-16 py-2.5 text-2xl font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
              onClick={() => document.getElementById('file-upload').click()}
            >
              UPLOAD IMAGE
            </button>

            <p className='mt-[1vh] text-xl text-gray-500'>or drag&drop it here.</p>
          </div>
        </div>
      </div>

      <div ref={exampleDivRef} className='flex flex-col items-center bg-white w-full'>
        <h1 className='text-2xl lg:text-6xl font-bold text-gray-900 mt-10 lg:mt-16 w-full lg:h-24 text-center'>Use Any Photo You Want!</h1>
        
        <div className='flex flex-row items-center justify-center space-x-2 mt-5 lg:mt-7'>
          <ExamplesButton selected={selectedExamples} setSelected={setSelectedExamples}>Business</ExamplesButton>
          <ExamplesButton selected={selectedExamples} setSelected={setSelectedExamples}>Art</ExamplesButton>
          <ExamplesButton selected={selectedExamples} setSelected={setSelectedExamples}>Photo</ExamplesButton>
          <ExamplesButton selected={selectedExamples} setSelected={setSelectedExamples}>Pet</ExamplesButton>
        </div>

        <div className='flex flex-col lg:flex-row lg:space-x-3 items-center lg:justify-center mt-5 mb-2 w-full space-y-3'>
          <AdvancedImage
            cldImg={cld.image(`assets/images/${selectedExamples.toLowerCase()}/3`)}
            className="h-full w-[90%] lg:w-[22%] object-cover rounded-lg"
            alt={`Custom rugs with ${selectedExamples} theme for our customers.`}
          />
          <AdvancedImage
            cldImg={cld.image(`assets/images/${selectedExamples.toLowerCase()}/4`)}
            className="h-full w-[90%] lg:w-[22%] object-cover rounded-lg"
            alt={`Custom rugs with ${selectedExamples} theme for our customers.`}
          />
          <AdvancedImage
            cldImg={cld.image(`assets/images/${selectedExamples.toLowerCase()}/2`)}
            className="h-full w-[90%] lg:w-[22%] object-cover rounded-lg"
            alt={`Custom rugs with ${selectedExamples} theme for our customers.`}
          />
          <AdvancedImage
            cldImg={cld.image(`assets/images/${selectedExamples.toLowerCase()}/1`)}
            className="h-full w-[90%] lg:w-[22%] object-cover rounded-lg"
            alt={`Custom rugs with ${selectedExamples} theme for our customers.`}
          />
        </div>

        <button
          className="shadow-lg shadow-amber-400 border border-black border-opacity-5 mt-[5vh] mb-10 rounded-lg bg-amber-400 px-16 py-2.5 text-2xl font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
          onClick={() => document.getElementById('file-upload').click()}
        >
          UPLOAD IMAGE
        </button>
      </div>
    </main>
  )
}

function ExamplesButton({children, selected, setSelected}) {
  return (
    <button
      type="button"
      className={classNames(
        'rounded-full w-16 lg:w-32 py-2 text-xs lg:text-lg font-semibold text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300',
        selected === children ? 'bg-amber-400 hover:bg-amber-400' : 'bg-white hover:bg-gray-50',
      )}
      onClick={() => setSelected(children)}
    >
      {children}
    </button>
  )
}
