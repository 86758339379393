import React from 'react'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

export default function CartEmpty() {
  return (
    <div className='flex flex-col w-screen h-screen items-center justify-center space-y-5'>
      <ShoppingCartIcon className='w-20 h-20' />
      <span className='items-center place-center font-bold text-2xl'>Your Cart is Empty!</span>
      <Link
        to="/customize/editor"
        className="shadow rounded-lg bg-amber-400 drop-shadow-2xl px-16 py-2.5 text-xl font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-amber-300"
      >
        CREATE YOUR RUG NOW
      </Link>
    </div>
  )
}
